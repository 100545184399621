import classNames from '@/utils/className';

interface ContainerProps {
  children: JSX.Element[] | JSX.Element;
  hasBreadcrumb?: boolean;
  className?: string;
}

export default function Container({ children, hasBreadcrumb = false, className = '' }: ContainerProps): JSX.Element {
  const variants = {
    hasBreadcrumb: {
      true: 'mt-4 desktop:mt-10',
      false: '',
    },
  } as const;

  return (
    <div className={classNames('w-full max-w-[calc(100%-2rem)] desktop:max-w-[83.33%]', variants.hasBreadcrumb[hasBreadcrumb.toString()], className)}>
      {children}
    </div>
  );
}
