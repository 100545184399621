/* eslint-disable jsx-a11y/anchor-is-valid */
import Link from 'next/link';
import { TimeLine } from '@/types/types';
import { getCurrentHub } from '@sentry/nextjs';
import classNames from '@/utils/className';
import { mglGray } from '@/styles/designSystemColors';
import ImageComponent from '../Image';
import Icon from '../Icon';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

export type PropsTitle = {
  color?: 'darkText' | 'lightText' | string;
  altColorInHover?: boolean;
  colorHover?: 'blue500' | string;
};

export type PropsAutor = {
  text?: boolean | true;
  image?: boolean | true;
  imgRounded?: boolean | true;
  imgInImage?: boolean | true;
};

export type PropsMetaInfo = {
  age?: boolean | true;
  category?: boolean | true;
};

interface NewsTimeLineProps {
  news: TimeLine;
  image?: boolean;
  effectImage?: boolean;
  title?: boolean | true;
  propsTitle?: PropsTitle;
  autor?: boolean | false;
  propsAutor?: PropsAutor;
  metaInfo?: boolean | false;
  propsMetaInfo?: PropsMetaInfo;
  isSubDomain?: boolean;
  url?: string;
  headingTag?: 'h2' | 'h3';
}

const icons = {
  video: 'mdi:video',
  podcast: 'mdi:microphone',
  webStories: 'mdi:view-carousel',
} as const;

export default function NewsTimeLine({
  news,
  image = true,
  effectImage = true,
  title = true,
  propsTitle,
  autor = false,
  propsAutor,
  metaInfo,
  propsMetaInfo,
  isSubDomain = false,
  url = null,
  headingTag = 'h2',
}: NewsTimeLineProps) {
  const variants = {
    ItemList: {
      effectImage: {
        true: '[&_.image-container]:hover:scale-105',
        false: '',
      },
      colorInHover: {
        true: 'transition-colors duration-300 ease-ease [&_h3]:hover:text-mglBlue400',
        false: '',
      },
    },
    Image: {
      imgRounded: {
        true: 'rounded-half border-[3px] border-solid border-mglGray200',
        false: '',
      },
    },
    Title: {
      autorNoMargin: {
        true: 'tablet:m-0 mt-0',
        false: '',
      },
    },
  } as const;
  const currentSpan = getCurrentHub().getScope()?.getSpan();
  const newsListContainerSpan = currentSpan && currentSpan.startChild({ op: 'newsListContainer' });
  const Heading = headingTag;

  url ||= news.url;
  if (isSubDomain && !url.match(/^https?:/)) {
    url = `${publicRuntimeConfig.NEXT_PUBLIC_URL}${url}`;
  }

  return (
    <article
      className={classNames(
        'relative mx-0 mb-0 mt-4 hover:cursor-pointer tablet:my-4 [&_a]:text-[inherit]',
        propsTitle.altColorInHover &&
          !!propsTitle.colorHover &&
          variants.ItemList.colorInHover[(propsTitle.altColorInHover && !!propsTitle.colorHover).toString()],
        effectImage != undefined && variants.ItemList.effectImage[effectImage.toString()]
      )}>
      <Link href={url} passHref>
        <a rel={news.id} title={news.titulo} className="jc">
          {image && (
            <div className="box relative flex overflow-hidden rounded-lg">
              <div className="image-container relative flex h-full w-full transition-transform duration-300 ease-ease [&>figure]:aspect-video">
                <ImageComponent src={news.imagem.url} alt={news.titulo} blurhash={news.imagem.blurhash} />
              </div>

              {autor && propsAutor && propsAutor.image && propsAutor.imgInImage && news.colunista && (
                <div className="absolute bottom-2 left-2 h-10 w-10">
                  <img
                    className={classNames(
                      'h-auto w-full object-cover',
                      propsAutor.imgRounded != null && variants.Image.imgRounded[propsAutor.imgRounded.toString()]
                    )}
                    src={news.colunista.imagem.url}
                    alt={news.autor}
                    width={40}
                    height={40}
                  />
                </div>
              )}
              {(news.tipo === 'video' || news.tipo === 'webStories' || news.tipo === 'podcast') && (
                <div className="absolute bottom-[10px] left-[10px] z-[2] flex h-10 w-10 items-center justify-center rounded-half border-[6px] border-solid border-[#999] bg-[#999] text-[2.5rem]">
                  <Icon icon={icons[news.tipo]} width="1em" color={mglGray.mglGray50} />
                </div>
              )}
            </div>
          )}
          {autor && propsAutor && propsAutor.image && !propsAutor.imgInImage && news.colunista && (
            <div className="relative -bottom-2 mb-4 ml-0 mr-2 mt-0 inline-block h-10 w-10">
              <img
                className={classNames(
                  'h-auto w-full object-cover',
                  propsAutor.imgRounded != null && variants.Image.imgRounded[propsAutor.imgRounded.toString()]
                )}
                src={news.colunista.imagem.url}
                alt={news.autor}
              />
            </div>
          )}
          {autor && propsAutor && propsAutor.text && <span className="mx-0 mb-2 mt-4 inline-block text-xs text-black/40">{news.autor}</span>}

          {metaInfo && (
            <div className="mb-[10px] mt-5 flex flex-row text-xs leading-none">
              {propsMetaInfo && propsMetaInfo.age && (
                <span className="flex flex-row items-center font-medium text-black/40 [&_svg]:mr-[2px]">
                  <Icon icon="mdi:clock-outline" width="12" height="12" />
                  {news.idade}
                </span>
              )}
              {propsMetaInfo && propsMetaInfo.age && propsMetaInfo.category && (
                <div className="mx-1 my-0 h-auto border-0 border-l-[0.5px] border-solid border-[#999]" />
              )}
              {propsMetaInfo && propsMetaInfo.category && <span className="font-medium uppercase text-black/40">{news.categoria}</span>}
            </div>
          )}
          {title && (
            <Heading
              className={classNames(
                'mt-4 text-lg font-medium text-mglGray900 tablet:mx-0 tablet:mb-0',
                ((propsAutor && !!propsAutor.text) || (metaInfo && !!propsMetaInfo)) && variants.Title.autorNoMargin.true
              )}
              style={propsTitle.color ? { color: propsTitle.color } : {}}>
              {news.titulo}
            </Heading>
          )}
        </a>
      </Link>
      {newsListContainerSpan && newsListContainerSpan.finish()}
    </article>
  );
}
