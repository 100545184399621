/* eslint-disable jsx-a11y/anchor-is-valid */
import Link from 'next/link';
import classNames from '@/utils/className';
import ImageComponent from '@/components/Layout/Image';

interface ChamadaNoticiaProps {
  url: string;
  imageURL?: string;
  eye: string;
  title: string;
  blurhash: string;
  featured: boolean;
}

export default function FeaturedNewsTitle({ url, eye, title, imageURL, blurhash, featured }: ChamadaNoticiaProps) {
  const variants = {
    ContainerNewsTitle: {
      featured: {
        true: 'desktop:[&_span]:text-base [&_span]:text-xs [&_span]:font-medium  desktop:[&_h2]:text-2xl [&_h2]:text-xl [&_h2]:font-medium',
        false: '[&_span]:text-xs [&_h2]:text-xl',
      },
    },
  } as const;

  return (
    <Link href={url} passHref>
      <a className="h-full w-full cursor-pointer last:[&>div]:hover:scale-105" title={title}>
        <div
          className={classNames(
            'absolute bottom-0 z-[3] block w-full p-6 text-left text-mglGray50',
            variants.ContainerNewsTitle.featured[featured.toString()]
          )}>
          <span className="mb-[10.24px] block font-medium uppercase text-mglGray50">{eye}</span>
          <h2 className="mb-[unset] block transform-none text-left font-medium text-mglGray50">{title}</h2>
        </div>
        <div className="relative h-full w-full transition-transform duration-300 ease-ease before:absolute before:bottom-0 before:left-0 before:right-0 before:top-0 before:z-[2] before:rounded before:content-[''] before:[background:linear-gradient(200deg,_rgba(34,_34,_34,_0)_0%,_rgba(34,_34,_34,_0.8)_100%)]">
          <ImageComponent src={imageURL} alt={title} blurhash={blurhash} />
        </div>
      </a>
    </Link>
  );
}
