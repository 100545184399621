import { Fragment, ReactNode } from 'react';
import Link from 'next/link';
import classNames from '@/utils/className';
import Icon from '../Icon';

const variants = {
  sizes: {
    small: 'h-6',
    medium: 'h-9',
    large: 'h-12',
  },
  backgroundColor: {
    white: 'chip-bg-color [&.chip-bg-color]:bg-mglGray50',
    black: 'chip-bg-color [&.chip-bg-color]:bg-mglGray900',
    green: 'chip-bg-color [&.chip-bg-color]:bg-mglGreen500',
  },
  color: {
    black: 'text-mglGray900',
    white: 'chip-color [&.chip-color]:text-mglGray50 [&.chip-color]:[&_svg]:fill-mglGray50',
  },
  hover: {
    white:
      'chip-hover-color hover:[&.chip-hover-color]:bg-mglGray50 hover:[&.chip-hover-color]:text-mglGray900 [&.chip-hover-color]:hover:[&_path]:fill-mglGray900',
    black:
      'chip-hover-color hover:[&.chip-hover-color]:bg-mglGray900 hover:[&.chip-hover-color]:text-mglGray50 [&.chip-hover-color]:hover:[&_path]:fill-mglGray50',
    gray: 'chip-hover-color hover:[&.chip-hover-color]:bg-mglGray200 hover:[&.chip-hover-color]:text-mglGray900 [&.chip-hover-color]:hover:[&_path]:fill-mglGray900',
    green:
      'chip-hover-color hover:[&.chip-hover-color]:bg-mglGreen600 hover:[&.chip-hover-color]:text-mglGray50 [&.chip-hover-color]:hover:[&_path]:fill-mglGray50',
  },
  borderColor: {
    gray: 'chip-border-color [&.chip-border-color]:border-mglGray900/20 hover:[&.chip-border-color]:border-mglGray50',
    black: 'border-mglGray900/20',
    white: 'chip-border-color [&.chip-border-color]:border-mglGray50/20',
    green: 'chip-border-color [&.chip-border-color]:border-mglGreen500 hover:[&.chip-border-color]:border-mglGreen600',
  },
} as const;

export interface ChipProps extends React.HTMLAttributes<HTMLAnchorElement | HTMLDivElement> {
  texto: string;
  highlightedText?: string;
  prepIcon?: 'chevron-right' | 'chevron-down' | ReactNode;
  posIcon?: 'chevron-right' | 'chevron-down' | ReactNode;
  backgroundColor?: keyof typeof variants.backgroundColor;
  borderColor?: keyof typeof variants.borderColor;
  link?: string;
  color?: keyof typeof variants.color;
  chipSize?: keyof typeof variants.sizes;
  hover?: keyof typeof variants.hover;
  title?: string;
  target?: '_blank' | '_self' | '_parent' | '_top';
}

export default function Chip({
  texto,
  prepIcon,
  posIcon,
  link = '#',
  color = undefined,
  backgroundColor = undefined,
  chipSize = 'small',
  borderColor = undefined,
  title = '',
  highlightedText = '',
  className,
  onClick,
  hover = undefined,
  rel = '',
  target = '_self',
}: ChipProps) {
  const Container = link[0] !== '#' ? Link : Fragment;
  const Tag = link !== '#' ? 'a' : 'div';
  const linkProps = {};

  if (link[0] !== '#') {
    Object.assign(linkProps, { href: link, passHref: true, prefetch: false });
  }

  return (
    <Container {...linkProps}>
      <Tag
        {...(link && link !== '#' && { href: link })}
        className={classNames(
          'relative flex cursor-pointer flex-row flex-nowrap items-center justify-center rounded-[100px] border border-solid px-4 no-underline transition-all ease-in [&_path]:transition-colors [&_path]:ease-ease',
          variants.sizes[chipSize],
          variants.backgroundColor[backgroundColor],
          variants.color[color],
          variants.borderColor[borderColor],
          variants.hover[hover],
          className
        )}
        rel={rel}
        title={title}
        onClick={onClick}
        target={target}>
        {!!prepIcon && typeof prepIcon === 'string' && (prepIcon === 'chevron-down' || prepIcon === 'chevron-right') ? (
          <span className="relative -m-1 flex pr-1">
            <Icon icon={`mdi:${prepIcon}`} width={16} />
          </span>
        ) : (
          !!prepIcon && typeof prepIcon !== 'string' && <span className="relative -m-1 flex pr-1">{prepIcon}</span>
        )}
        <div className="flex flex-col">
          <span className="relative mt-[-2px] whitespace-nowrap text-center text-xs no-underline">{texto}</span>
          {!!highlightedText && <span className="text-base font-semibold leading-none">{highlightedText}</span>}
        </div>
        {!!posIcon && typeof posIcon === 'string' && (posIcon === 'chevron-down' || posIcon === 'chevron-right') ? (
          <span className="relative -m-1 flex pl-1 [&_svg]:w-4">
            <Icon icon={`mdi:${posIcon}`} width={16} />
          </span>
        ) : (
          !!posIcon && typeof posIcon !== 'string' && <span className="relative -m-1 flex pl-1">{posIcon}</span>
        )}
      </Tag>
    </Container>
  );
}
